<template>
  <div>
    <div class='title'>登录日志</div>
    <div class="operation-btn">
      <el-form :inline='true' class='demo-form-inline'>
        <el-form-item>
          <el-date-picker
            v-model="queryParam.searchBeginTime"
            type="date"
            placeholder="开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="queryParam.searchEndTime"
            type="date"
            placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim='queryParam.name' placeholder='请输入日志名称'></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' @click="getLogList">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button type='danger' :disabled="!this.multipleSelection.length" @click="clearLog">清空日志</el-button>
    </div>
    <div class="table-box">
      <el-table
        :data="tableData"
        stripe
        size="medium"
        ref="dictionaryTable"
        v-loading="loading"
        style='width: 100%'
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          align='center'
          width="60">
        </el-table-column>
        <el-table-column
          prop='account'
          label='账号'
          sortable
          align='center'
          width='180'>
        </el-table-column>
        <el-table-column
          prop='name'
          label='用户名称'
          align='center'
          sortable
          width='220'>
        </el-table-column>
        <el-table-column
          prop='visTime'
          align='center'
          sortable
          label='时间'>
          <template slot-scope="{ row }">{{row.visTime || '--'}}</template>
        </el-table-column>
        <el-table-column
          prop='message'
          sortable
          width='220'
          align='center'
          label='具体消息'>
          <template slot-scope="{ row }">{{row.message || '--'}}</template>
        </el-table-column>
        <el-table-column
          prop='ip'
          sortable
          align='center'
          label='IP地址'>
        </el-table-column>
      </el-table>
      <div class='pagination'>
        <el-pagination
          :total='total'
          :current-page="queryParam.pageNo"
          :page-size="queryParam.pageSize"
          background
          @size-change='handleSizeChange'
          @current-change='handleCurrentChange'
          :page-sizes='[10, 20, 30, 50]'
          layout='total, prev, pager, next, sizes'>
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getLogList, deleteLog } from '@/api/main/system/loginLog'
export default {
  data() {
    return {
      queryParam: {
        pageNo: 1,
        pageSize: 10,
        name: '',
        searchBeginTime: '',
        searchEndTime: '',
      },
      total: 0,
      loading: false,
      tableData: [],
      multipleSelection: [],
    }
  },
  created() {
    this.getLogList()
  },
  methods: {
    // 获取登录日志
    getLogList() {
      this.loading = true
      getLogList(this.queryParam).then(res => {
        this.tableData = res.data.rows
        this.total = res.data.totalRows
      }).finally(() => {
        this.loading = false
      })
    },
    handleSizeChange(size) {
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = size
      this.getLogList()
    },
    handleCurrentChange(page) {
      this.queryParam.pageNo = page
      this.getLogList()
    },
    // 重置
    resetForm() {
      this.queryParam = {
        pageNo: 1,
        pageSize: 10,
        name: '',
        searchBeginTime: '',
        searchEndTime: '',
      }
      this.getLogList()
    },
    // 清空日志
    clearLog() {
      this.$confirm('此操作将永久删除该日志, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = this.multipleSelection.map(item => item.id).join(',')
        deleteLog({ids}).then(res => {
          this.$message.success('删除成功')
          this.getLogList()
        })
      }).catch(() => {
        this.$refs.dictionaryTable.clearSelection();
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang='less' scoped>
.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #E8E8E8;
}
.operation-btn{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 16px;
  border-bottom: 1px solid #E8E8E8;
}
.demo-form-inline{
}
.table-box{
  padding-top: 24px;
}
.pagination {
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
