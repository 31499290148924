import { axios } from '@/utils/request';
/**
 * 分页查询登录日志
 */
export function getLogList(parameter) {
  return axios({
    url: '/sysVisLog/page',
    method: 'get',
    params: parameter
  });
}
/**
 * 清空日志
 */
export function deleteLog(parameter) {
  return axios({
    url: '/sysVisLog/delete',
    method: 'post',
    data: parameter
  });
}
